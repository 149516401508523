(function() {
   let myAgenda = [];

   function launchEmailerModal() {
      const toEmail = myAgenda.map(id => _sessions[id]);
      const formattedSessions = toEmail.map(s => (/*html*/`
         <li>
            <div style="color: gray">
               ${s.session_start_time_display} – ${s.session_end_time_display}
            </div>
            <div>${s.name}</div>
         </li>`
      ));
      const content = /*html*/`
         <div class="email-my-agenda">
            <h1>Email Me My Agenda</h1>
            <ul class="list-unstyled">
               ${formattedSessions.join('\n')}
            </ul>
            <div class="wrap-functions">
               <input required id="email-recipient" type="email" class="form-control" placeholder="your@email.com" />
               <button class="btn btn-send-my-agenda disabled">Send My Agenda</button>
            </div>
            <div class="feedback"></div>
         </div>
      `;
      jQuery('#evtx-modal .modal-body')
         .addClass('email-my-agenda-modal')
         .html(content)
      jQuery('#evtx-modal').modal('show');
   }

   jQuery(document).on('keyup', '#email-recipient', function (e){
      if (jQuery(this).is(':invalid')) {
         jQuery('.btn-send-my-agenda')
            .addClass('disabled')
      }
      else {
         jQuery('.btn-send-my-agenda')
            .removeClass('disabled')   
      }

   })

   jQuery(document).on('click', '.btn-send-my-agenda', function() {
      if (jQuery('#email-recipient').is(':invalid')) {
         alert('Please enter a valid email address.');
         return;
      }
      const email = jQuery('#email-recipient').val();
      const ids = myAgenda;
      const data = {
         action: 'email_my_agenda',
         options: _session_opts,
         ids,
         email,
      };
      jQuery.post(ajaxurl, data, function(response) {
         if (response.success) {
            jQuery('.email-my-agenda .feedback')
               .removeClass('error')
               .addClass('success')
               .html('Email successfully sent.')
            setTimeout(function(){
               myAgenda = [];
               jQuery('#evtx-modal').modal('hide');
               updateEmailButton();
            }, 2000);
         }
         else {
            jQuery('.email-my-agenda .feedback')
               .removeClass('success')
               .addClass('error')
               .html('Error sending email')
         }
      });
   })

   jQuery(document).on('click', '.btn-email-my-agenda', function() {
      launchEmailerModal();
   });

   function toggleSessionID(id) {
      const idx = myAgenda.indexOf(id);
      if (idx >= 0) {
         myAgenda.splice(idx, 1);
      }
      else {
         myAgenda.push(id);
      }

      // update buttons to reflect activated sessions
      jQuery('.btn-add-to-my-agenda').each(function() {
         const $this = jQuery(this);
         const myID = $this.attr('data-session-id');
         if (myAgenda.includes(myID)) {
            $this.addClass('active');
            jQuery('.btn-email-my-agenda')
               .addClass('added');
            setTimeout(function() {
               jQuery('.btn-email-my-agenda')
                  .removeClass('added');
            }, 500);
         }
         else {
            $this.removeClass('active');
         }
      });
      updateEmailButton();
   }
   jQuery('#evtx-modal').on('hidden.bs.modal', function (e) {
      jQuery(this)
         .find('.modal-body')
         .removeClass('.email-my-agenda-modal');
   });
   jQuery(document).on('click', '.btn-add-to-my-agenda', function() {
      const $this = jQuery(this);
      const sessionID = $this.attr('data-session-id');
      toggleSessionID(sessionID);
   });

   function updateEmailButton () {
      jQuery('.btn-email-my-agenda')
         .attr('data-num-sessions', myAgenda.length);
      if (myAgenda.length) {
         jQuery('.btn-email-my-agenda')
            .find('span')
            .html(`Email Me ${myAgenda.length} Sessions`);
      }
   }

})();
