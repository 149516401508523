// Scroll position on page where back to top button first appears
const BACK_TO_TOP_THRESH = 350;

const FADE_SPEED = 100;

const $window = jQuery(window);
const $document = jQuery(document);

// Scroll to top of page on click
$document.on('click', '.back-to-top', function() {
   $window.scrollTop(0);
});

let backToTopVisible = false;
$window.on('scroll', function() {
   requestAnimationFrame(() => {
      const scrollPos = $window.scrollTop();
      if (scrollPos > BACK_TO_TOP_THRESH && !backToTopVisible) {
         backToTopVisible = true;
         jQuery('.back-to-top').fadeIn(FADE_SPEED);
      }
      else if (scrollPos <= BACK_TO_TOP_THRESH && backToTopVisible) {
         backToTopVisible = false;
         jQuery('.back-to-top').fadeOut(FADE_SPEED); 
      }
   });
})