import { SESSION_HASH } from "./routing";


export function launchSpeakerModal(id) {
   const s = window._speakers[id];
   const $modal = jQuery('#evtx-modal');

   const sessions = s.sessions.map(session => (
      `<li>
         <a class="session-link" href="${SESSION_HASH}${session.id}">
            ${session.name}
         </a>
      </li>`
   ));
   const content = /*html*/`
      <div class="sf-speaker-modal">
         <h1>${s.name}</h1>
         <div class="title-company">
            <span class="title">${s.title}</span>
            <span class="company">${s.company}</span>
         </div>
         <ul class="list-unstyled speaker-sessions">${sessions.join('')}</ul>
         <section>
            <div class="speaker-image-wrap">
               <img class="speaker-image" src="${s.image.url}" alt="${s.name}"/>
            </div>
            ${s.bio ? `<p>${s.bio}</p>` : ''}
         </section>
         <div class="speaker-share">
            <div class="addthis_toolbox addthis_default_style"
               addthis:url=""
               addthis:title=""
               addthis:description=""
               addthis:media=""
            >
               <a class="addthis_button_facebook at300b" title="Facebook" href="#"><span><i class="fa fa-facebook-f"></i></span></a>
               <a class="addthis_button_twitter at300b" title="Tweet" href="#"><span><i class="fa fa-twitter"></i></span></a>
               <a class="addthis_button_linkedin at300b" title="LinkedIn" href="#" target="_blank" rel="noopener"><span><i class="fa fa-linkedin"></i></span></a>
               <a class="addthis_button_email at300b" target="_blank" rel="noopener" title="Email" href="#"><span><i class="fa fa-envelope"></i></span></a>
            </div>
         </div>
      </div>
   `;
   $modal.find('.modal-body').html(content);
   launchAddThis(
      '.sf-speaker-modal .addthis_toolbox',
      {
         url: location.href,
         description: s.bio,
         media: s.image.url || '',
         title: s.name
      },
   );
   
   $modal.modal('show');
}

function launchAddThis(selector, {url, description, media, title}) {
   const $at = jQuery(selector);
   if (!$at.length) {
      console.warn('AddThis toolbox div not found.');
      return;
   }
   if (!window.addthis) {
      console.warn('AddThis not found.')
      return;
   }
   
   window.addthis_share = {url, title, media, description};

   $at.attr('addthis:url', url);
   $at.attr('addthis:title', title);
   $at.attr('addthis:description', description);
   $at.attr('addthis:media', media);
   
   window.addthis.toolbox(selector);
}

jQuery(document).on('click', '.session-speaker', function(){
   var id = jQuery(this).attr('data-id');
   launchSpeakerModal(id);
});

jQuery(document).on('click', '.sf-speaker-modal .session-link', function() {
   jQuery('#evtx-modal').modal('hide');
});

window.addEventListener('hashchange', function(){
   console.log('hi');
   console.log(location.hash);
})