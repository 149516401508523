import * as R from 'ramda';
const notEmpty = R.complement(R.isEmpty);

const listToggle = (item, list) => R.ifElse(
   R.includes(item),
   R.without([item]),
   R.append(item)
)(list);

var trackFilters = [];

const CLASS_HIDE = 'hide';
const CLASS_ACTIVE = 'active';

function sessionVisibility($s, visible = true) {
   if (visible) {
      $s
         .removeClass(CLASS_HIDE)
         .find('.session-content')
         .collapse('show')
   }
   else {
      $s
         .addClass(CLASS_HIDE)
         .find('.session-content')
         .collapse('hide');
   }
}

// update search needle when input changes
var searchText = '';
var searchBox = document.getElementById('sessions-search');
if (searchBox) {
   searchBox.addEventListener('keyup', function(e) {
      const next = jQuery('#sessions-search').val().toLowerCase().trim();
      if (next !== searchText) {
         searchText = next;
         updateSessionFilters();
         jQuery(window).scrollTop(0);
      }
      console.log(searchText);
   });
}

function updateSessionFilters() {
   jQuery('.btn-track-filter').each(function(){
      var $this = jQuery(this);
      var t = $this.attr('data-track-target');
      if (trackFilters.includes(t)) {
         $this.addClass(CLASS_ACTIVE);
      }
      else {
         $this.removeClass(CLASS_ACTIVE);
      }
   });

   if (!trackFilters.length && !searchText) {
      // show all sessions, but collapse
      jQuery('.session')
         .removeClass(CLASS_HIDE)
         .find('.session-content')
         .collapse('hide');
      jQuery('.agenda-day').removeClass(CLASS_HIDE);
      jQuery('.clear-filters').hide();
      jQuery(window).scrollTop(0);
      jQuery('.nav--event-dates a').fadeIn(100);
      return;
   }
   jQuery('.clear-filters').show();
   
   let total = 0;
   console.log(searchText);
   jQuery('.session').each(function() {
      var $this = jQuery(this);

      // check if tracks match filter
      var myTracks = $this
         .attr('data-tracks')
         .split(', ');

      const searchHaystack = $this.text().toLowerCase();

      const foundInSearch = searchText
         ? searchHaystack.includes(searchText)
         : true;

      const keywordsFound = trackFilters.length
         ? notEmpty(R.intersection(myTracks, trackFilters))
         : true;
      
      const visible = foundInSearch && keywordsFound;
      
      if (visible) {
         total++;
      }
      sessionVisibility($this, visible);
   });
   
   if (!total) {
      jQuery('.none-found').show();
   }
   else {
      jQuery('.none-found').hide();
   }

   jQuery('.agenda-day').each(function(){
      var $this = jQuery(this);
      const id = $this.attr('id');
      const visibleSessions = $this.find(`.session:not(.${CLASS_HIDE})`);
      // const visibleSessions = $this
      //    .find('.session:visible');
      const $navItem = jQuery('.nav--event-dates')
         .find(`a[href="#${id}"]`);

      if (visibleSessions.length) {
         $this.removeClass(CLASS_HIDE);
         $navItem.fadeIn(100);
      }
      else {
         $this.addClass(CLASS_HIDE);
         $navItem.fadeOut(100);
      }
   });


}

jQuery(document).on('click', '.btn-track-filter', function(){
   var $this = jQuery(this);
   var t = $this.attr('data-track-target');
   trackFilters = listToggle(t, trackFilters);
   updateSessionFilters();
});

jQuery(document).on('click', '.clear-filters', function(){
   trackFilters = [];
   searchText = '';
   jQuery('#sessions-search').val('');
   updateSessionFilters();
})
