import 'url-polyfill';
import { launchSpeakerModal } from "./speaker-modals";

export const SF_QUERY_VAR = 'sid';
export const SESSION_HASH = '#session-';

function getSFQueryVar(url) {
   const u = new URL(url);
   const params = new URLSearchParams(u.search); 
   return params.get(SF_QUERY_VAR);
}

function setSFQueryVar(url, id) {
   const u = new URL(url);
   const params = new URLSearchParams(u.search);
   params.set(SF_QUERY_VAR, id);
   u.search = params.toString();
   return u.toString(); 
}

function withoutSFQueryVar(url) {
   const u = new URL(url);
   const params = new URLSearchParams(u.search);
   params.delete(SF_QUERY_VAR);
   u.search = params.toString();
   return u.toString();
}

// catch links that start with ?sid=
jQuery(document).on('click', `[href^="?${SF_QUERY_VAR}="]`, function(e) {
   const href = jQuery(this).attr('href');
   const p = new URLSearchParams(href);
   const id = p.get(SF_QUERY_VAR);
   
   if (!id) return;

   // don't reload page
   e.preventDefault();
   e.stopPropagation();
   
   // add ID to query params of current url
   const nextURL = setSFQueryVar(location.href, id)

   history.pushState({id}, '', nextURL);

   launchSpeakerModal(id);
});

jQuery(document).ready(function(){
   const id = getSFQueryVar(location.href);
   if (id) {
      launchSpeakerModal(id);
   }
   routeSessionHash();
});
jQuery(window).load(function(){
   
})


jQuery('#evtx-modal').on('hidden.bs.modal', function (e) {
   const $m = jQuery('#evtx-modal');
   // remove salesforce query variable if in an sf-speaker-modal
   if ($m.find('.sf-speaker-modal').length) {
      history.pushState(
         {},
         '',
         withoutSFQueryVar(location.href)
      );
   }
})

function routeSessionHash() {
   const hash = location.hash;
   if (hash.indexOf(SESSION_HASH) === 0) {
      // we have a session url
      const id = hash.replace(SESSION_HASH, '');
      const $el = jQuery(SESSION_HASH + id);
      if ($el.length) {
         $el.collapse('show');
         setTimeout(function(){
            const offset = $el.offset().top;
            jQuery(window).scrollTop(offset - 150);
         }, 800);
      }
      return true;
   }
   return false;
}

// expanding a session updates URL
jQuery(document).on('click', '.session [data-toggle="collapse"]', function(){
   const href = jQuery(this).attr('href');
   history.pushState({}, '', href);

})

window.addEventListener('hashchange', function(e) {
   // prevent default if hash change handled
   if (routeSessionHash()) {
      e.preventDefault();
   }
});